import Link from 'next/link';
import Image from 'next/image';
import styles from './Single.module.css';

export default function Single({ children }) {
	return (
		<div className={styles.container}>
			<header>
				<Link href="/">
					<a>
						<Image src="/logo.svg" width={86} height={30} />
					</a>
				</Link>
			</header>
			<section className={styles.center}>{children}</section>
			<footer>hecho con 🌶 en 🇨🇱</footer>
		</div>
	);
}
